import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ScratchCard } from "next-scratchcard";
import { API_URL } from "../../services";
import { RemoveScrollBar } from "react-remove-scroll-bar";

import "./giftView.css";

export default function GiftView() {
  const location = useLocation();
  const [gift, setGift] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [percent, setPercent] = useState(0);

  const [images, setImages] = useState(true);

  const scannerResult = location?.state;
  const Coupen_id = scannerResult?._id;
  // console.log("Coupen_id", Coupen_id);
  // console.log("scannerResult", scannerResult);
  const Images = scannerResult.images;
  const navigate = useNavigate();
  const path = () => {
    navigate("/scratchandwin");
  };

  const handleComplete1 = () => {
    setPercent(100);
  };

  const handleProgress = (remaining) => {
    const newPercent = 100 - remaining;
    setPercent(newPercent);
  };

  const handleComplete = () => {
    console.log("Scratch card completed!");
    setImages(false);
    scarth();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 3000);

    // Cleanup the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  const scarth = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      coupon_id: Coupen_id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    // console.log("sending data", raw);

    fetch(API_URL + "scratchCoupen", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("couponscarthed", result);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <div className="home-page1">
      <div className="overflow-hidden  overflow-x-hidden">
        <RemoveScrollBar />
        {scannerResult ? (
          <>
            {/* <div className="flex items-center flex-col">
            <p className="font-bold text-2xl pt-[20px]">
              Reedem Your Gift From Shop
            </p>
          </div> */}
            <div className="flex items-center justify-center h-screen flex-col relative">
              {/* <p className='font-bold text-5xl text-gold-text pt-20' style={{ fontFamily: 'fontzillion' }}>
    You Win
  </p>
  <p className='font-bold text-2xl mt-6' style={{ fontFamily: 'fontzillion' }}>
    Gift Voucher
  </p> */}
              {images == false ? (
                <div className="flex items-center justify-center ">
                  <div className="absolute pt-[270px] z-10">
                    {/* <img src={Images} alt="image"width={300} height={200} /> */}
                    <p
                      style={{
                        color: "#000000",
                        fontSize: 24,
                        fontWeight: 600,
                      }}
                    >
                      {scannerResult?.giftname?.length > 20
                        ? `${scannerResult.giftname.substring(0, 20)}...`
                        : scannerResult?.giftname}
                    </p>
                  </div>
                </div>
              ) : null}

              <div className="absolute top-45 left-0 w-full h-full flex items-center justify-center ">
                <ScratchCard
                  height={650}
                  width={380}
                  finishPercent={40}
                  brushSize={30}
                  image={require("../assetes/BeforeScrachCard.png")}
                  onComplete={handleComplete}
                >
                  {images ? (
                    <div className="absolute pt-[0px]  z-20">
                      <img
                        src={require("../assetes/scratchAfter1.png")}
                        style={{
                          width: 630,
                          height: 650,
                          position: "relative",
                          // objectFit:'cover'
                        }}
                      />
                      <img
                        src={Images}
                        alt="image"
                        style={{
                          position: "absolute",
                          width: 250,
                          height: 185,
                          bottom: 175,
                          left: 75,
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  ) : (
                    <img
                      src={require("../assetes/scratchAfter.png")}
                      style={{
                        width: 380,
                        height: 650,
                        position: "relative",
                      }}
                    />
                  )}
                </ScratchCard>
              </div>
            </div>
          </>
        ) : (
          <div className="border-solid border-red-200 bg-white flex justify-center rounded-lg items-center py-48 flex-col ">
            <img
              width={200}
              height={200}
              src={require("../assetes/wrong.png")}
              alt=""
            />
            <span className="text-red-500 text-2xl flex items-center  justify-center ">
              Oop's Somenthing Went Wrong!!..
            </span>
            <span className="cursor-pointer" onClick={() => path()}>
              Back to home
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
