import { useNavigate } from "react-router-dom";
import { API_URL } from "../../services";
import { useState, useEffect, useRef } from "react";
import SweetAlert from "sweetalert-react";
import Swal from "sweetalert2";
import {
  zeroRightClassName,
  fullWidthClassName,
  noScrollbarsClassName,
} from "react-remove-scroll-bar";
import { FaUser, FaPhone, FaEnvelope } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";

// import { Container, Card, ListGroup } from 'react-bootstrap';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { CustomScroll } from "react-custom-scroll";

import "./Home.css";
export default function Home() {
  const [error, setError] = useState();
  const [userName, setUserName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [couponId, setCouponId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  const [data, setData] = useState([]);

  const [position, setPosition] = useState({
    top: window.innerHeight - 100,
    left: window.innerWidth - 50,
  });

  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const scrollContainerRef = useRef(null);
  const itemRefs = useRef([]);
  const scrollIntervalRef = useRef(null);

  const navigate = useNavigate();

  const updateDeviceWidth = () => {
    setDeviceWidth(window.innerWidth);
  };

  useEffect(() => {
    getAllUsersData();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      startAutoScroll();
    }

    return () => {
      clearInterval(scrollIntervalRef.current);
    };
  }, [data]);

  const getAllUsersData = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(API_URL + "get_all_coupen_display", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result.response);
        setData(result.response);
      })
      .catch((error) => console.log("error", error));
  };

  const startAutoScroll = () => {
    const scrollContainer = scrollContainerRef.current;

    scrollIntervalRef.current = setInterval(() => {
      if (scrollContainer) {
        if (
          scrollContainer.scrollLeft + scrollContainer.clientWidth >=
          scrollContainer.scrollWidth
        ) {
          scrollContainer.scrollLeft = 0;
        } else {
          scrollContainer.scrollLeft += scrollContainer.clientWidth;
        }
      }
    }, 3000); // Adjust the interval as needed
  };

  const scrollToPreviousItem = () => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.scrollLeft -= scrollContainer.clientWidth;
      if (scrollContainer.scrollLeft < 0) {
        scrollContainer.scrollLeft = scrollContainer.scrollWidth;
      }
    }
  };

  const scrollToNextItem = () => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.scrollLeft += scrollContainer.clientWidth;
      if (scrollContainer.scrollLeft >= scrollContainer.scrollWidth) {
        scrollContainer.scrollLeft = 0;
      }
    }
  };

  const path = () => {
    navigate("/Scanner");
  };

  function Warningalert(errorMessage) {
    Swal.fire({
      title: errorMessage,
      icon: "warning",
    });
  }

  const userLogin = (e) => {
    e.preventDefault();
    setIsLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      phone: number,
      userName: userName,
      email: email,
      // branchCode: couponId,
    });
    console.log("raw", raw);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(API_URL + "userRegistration", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("user Login", result);
        if (result.status === true) {
          if (!/^\d+$/.test(number)) {
            // If the number is not valid, show a warning alert
            Warningalert("Number is not valid");
            setIsLoading(false); // Set isLoading back to false
            return; // Return to prevent further execution
          } else {
            setIsLoading(false);
            navigate("/Scanner", {
              state: result.response,
            });
          }
        } else {
          // if(result.message === 'Please Provide All The details'){
          setError(result.message);
          Warningalert(result.message);
          setIsLoading(false);
          // }
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        setIsLoading(false);
      });
  };
  // console.log(deviceWidth);

  // const scrollContainerRef = useRef(null);
  // const itemRefs = useRef([]);

  // const scrollToItem = (index) => {
  //   if (itemRefs.current[index]) {
  //     itemRefs.current[index].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
  //   }
  // };

  // const scrollToNextItem = () => {
  //   const currentIndex = itemRefs.current.findIndex((ref) => ref.getBoundingClientRect().left >= 0);
  //   const nextIndex = currentIndex < data.length - 1 ? currentIndex + 1 : 0;
  //   scrollToItem(nextIndex);
  // };

  // const scrollToPreviousItem = () => {
  //   const currentIndex = itemRefs.current.findIndex((ref) => ref.getBoundingClientRect().left >= 0);
  //   const previousIndex = currentIndex > 0 ? currentIndex - 1 : data.length - 1;
  //   scrollToItem(previousIndex);
  // };

  const handleChange = (e) => {
    const value = e.target.value;
    const alphabeticValue = value.replace(/[^a-zA-Z]/g, ""); // Remove non-alphabetic characters
    setUserName(alphabeticValue);
  };

  const handleChangeNumber = (e) => {
    const { value } = e.target;
    // Allow only numeric values and limit to 10 digits
    if (value.length <= 10 && /^\d*$/.test(value)) {
      setNumber(value);
    }
  };

  const handleCallClick = () => {
    // window.location.href = "tel:+965-55484001";
    // window.location.href = "https://api.whatsapp.com/send?phone=96566822460";
    window.open("https://api.whatsapp.com/send?phone=96566822460", "_blank");
  };

  const termsModal = () => {
    Swal.fire({
      title: "<strong>Terms & Conditions :</strong>",
      html: `
        <div>
          <p>* For Charge - One Chance will be issued against single cash invoice worth more than KD 9/- & on Any Top Up Package related to KD 19 you get 2 Chances, on KD 34 you get 3 Chances & KD 49 you get 5 Chances.</p>
          <p>* For House of X - On Any Purchase of Top Up Package related to KD 25/- you get 2 Chances & on KD 49/- you get 5 Chances.</p>
          <p>* To Claim prize, please present the scratch card screenshot to the Branch Customer Service Representative along with the invoice.</p>
          <p>* The prize cannot be exchanged for any other prize or cash.</p>
          <p>* Offer Valid from 15th July to 19th Aug 2024.</p>
          <p>* Last date to redeem prizes is 25th Aug 2024</p>
        </div>
      `,
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonAriaLabel: "Close",
    });
  };

  const CommanModal = ({ show, handleClose }) => {
    if (!show) {
      return null;
    }
    return (
      <div className="modal-overlay">
        <div
          className="modal show"
          style={{ display: "block", position: "initial" }}
        >
          <Modal.Dialog>
            <Modal.Header closeButton>
              <Modal.Title>Modal title</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <p>Modal body text goes here.</p>
            </Modal.Body>
            <span className="close-button" onClick={handleClose}>
              &times;
            </span>

            <Modal.Footer>
              <Button variant="secondary">Close</Button>
              <Button variant="primary">Save changes</Button>
            </Modal.Footer>
          </Modal.Dialog>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const moveRocket = () => {
      setPosition((prev) => {
        let newTop = prev.top - 5;
        let newLeft = prev.left - 5;

        // Reset the position when the rocket reaches the top-left corner
        if (newTop <= -190 || newLeft <= -50) {
          newTop = window.innerHeight;
          newLeft = window.innerWidth;
        }

        return { top: newTop, left: newLeft };
      });
    };

    const intervalId = setInterval(moveRocket, 100);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="home-page">
      <div className="container">
        <div className="black-ball-conatiner">
          <img className="black-ball" src={require("../assetes/7.png")} />
        </div>
        <div className="ball-icon-conatiner">
          <img className="ball-icon" src={require("../assetes/6.png")} />
        </div>
        <div className="input-container">
          <FaUser className="icon" />
          <input
            className="input"
            placeholder="Name*"
            type="text"
            value={userName}
            // onChange={setUserName}
            onChange={(e) => setUserName(e.target.value)}
          />
        </div>
        <div className="input-container">
          <FaPhone className="icon" />
          <input
            className="input"
            placeholder="Number*"
            type="text"
            value={number}
            maxLength={8}
            // onChange={handleChangeNumber}
            inputMode="numeric"
            onChange={(e) => setNumber(e.target.value)}
          />
        </div>
        <div className="input-container">
          <FaEnvelope className="icon" />
          <input
            className="input"
            placeholder="Email ID"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        {/* <div className="input-container">
          <FaTag className="icon" />
          <input
            className="input"
            placeholder="Coupon Code"
            type="text"
            value={couponId}
            onChange={(e) => setCouponId(e.target.value)}
          />
        </div> */}
      </div>

      <div className="submitButton">
        <img
          src={require("../assetes/submit1.png")}
          width={250}
          height={250}
          onClick={(e) => userLogin(e)}
          className="callus-image"
        />
      </div>
      {/* <div className="poll-ball-conatiner">
        <img className="poll-ball-icon" src={require("../assetes/4.png")} />
      </div> */}

      <div className="mainAnimation">
        <div className="divText">
          <text className="text">Gifts</text>
        </div>

        <div className="app">
          <div className="carousel-container">
            <button className="arrow-button" onClick={scrollToPreviousItem}>
              {"<"}
            </button>
            <div className="carousel" ref={scrollContainerRef}>
              {data?.map((item, index) => (
                <div
                  className="carousel-item"
                  key={index}
                  ref={(el) => (itemRefs.current[index] = el)}
                >
                  <img src={item.gift.images} alt={item.alt} />
                  <p
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item?.gift?.giftname?.length > 15
                      ? item?.gift?.giftname.substring(0, 15)
                      : item?.gift?.giftname}
                  </p>
                </div>
              ))}
            </div>
            <button className="arrow-button" onClick={scrollToNextItem}>
              {">"}
            </button>
          </div>
        </div>
        <div className="termsCondition">
          <img
            src={require("../assetes/termsCondition.png")}
            width={160}
            height={400}
            onClick={termsModal}
            className="callus-image"
          />

          <img
            src={require("../assetes/whatsapp.png")}
            width={160}
            height={400}
            onClick={handleCallClick}
            className="callus-image"
          />
          {/* <div style={{ textAlign: "center" }} onClick={handleCallClick}>
            <IoLogoWhatsapp
              color="#E39026"
              className="callus-image"
              style={{ width: 50, height: 50,paddingLeft:'10px' }}
            />
            <p className="callus-image" style={{ color: "#E39026" }}>WhatsApp</p>
          </div> */}

          {/* <CommanModal show={showModal} handleClose={closeModal} /> */}
        </div>
      </div>
      <div className="socialLink">
        <a
          href=" https://www.instagram.com/charge_kw/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={require("../assetes/insta.png")}
            width={120}
            height={120}
            className="animated-image"
          />
        </a>
        <a
          href="https://www.instagram.com/houseofx_kw/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={require("../assetes/insta.png")}
            width={120}
            height={120}
            // style={{ marginTop: 20 }}
            className="animated-image"
          />
        </a>
      </div>
      <div className="rocketModel">
        <img
          src={require("../assetes/11.png")}
          className="rocket"
          style={{
            top: `${position.top}px`,
            left: `${position.left}px`,
            zIndex: 10,
          }}
          alt="Rocket"
        />
      </div>
    </div>
  );
}
