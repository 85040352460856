import { Html5QrcodeScanner, Html5Qrcode } from "html5-qrcode";
import { useEffect, useState, useRef } from "react";
// import ScratchCard from './Scrathcrad';
import Swal from "sweetalert2";

import { useLocation, useNavigate } from "react-router-dom";
import { API_URL } from "../../services";
import "./scanner.css";
export default function Scanner() {
  const navigate = useNavigate();
  const location = useLocation();
  const prev_response = location?.state;
  const User_id = prev_response?._id;
  console.log("user_id", User_id);
  const [scanner, setScanner] = useState(null);
  const html5QrCodeRef = useRef(null);
  const isScannerRunning = useRef(false);
  const [isScanning, setIsScanning] = useState(true);
  const [scanning, setScanning] = useState(false);

  useEffect(() => {
    // Function to initialize the QR code scanner
    const initQrScanner = async () => {
      const config = {
        fps: 10,
        qrbox: 150,
        aspectRatio: 1.7777778,
        disableFlip: false,
        videoConstraints: {
          facingMode: { exact: "environment" },
        },
      };

      html5QrCodeRef.current = new Html5Qrcode("reader");
      try {
        await html5QrCodeRef.current.start(
          { facingMode: { exact: "environment" } },
          config,
          onScanSuccess,
          onScanFailure
        );
        isScannerRunning.current = true;
        setScanning(true);
      } catch (err) {
        console.error("Failed to start QR code scanner", err);
      }
    };

    // Initialize the QR code scanner
    initQrScanner();

    // Cleanup function to stop the scanner
    return () => {
      if (isScannerRunning.current && html5QrCodeRef.current) {
        html5QrCodeRef.current
          .stop()
          .then(() => {
            isScannerRunning.current = false;
            setScanning(false);
          })
          .catch((err) => console.error("Failed to stop QR code scanner", err));
      }
    };
  }, []);

  const onScanSuccess = async (decodedText, decodedResult) => {
    if (isScanning) {
      setIsScanning(false);
      console.log(`Scan result: ${decodedText}`, decodedResult);

      // Stop the scanner to prevent multiple scans
      if (isScannerRunning.current && html5QrCodeRef.current) {
        await html5QrCodeRef.current
          .stop()
          .then(() => {
            isScannerRunning.current = false;
          })
          .catch((err) => console.error("Failed to stop QR code scanner", err));
      }

      ScanQR(decodedText);
    }
  };

  const onScanFailure = (error) => {
    console.warn(`QR Code scan error: ${error}`);
  };

  function Warningalert(errorMessage) {
    Swal.fire({
      title: errorMessage,
      icon: "warning",
    });
  }

  const ScanQR = (branch_id) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      user_id: User_id,
      branch_id: branch_id,
    });

    console.log(raw);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("QR scanning", raw);
    fetch(API_URL + "user_generate_Coupen", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("Generated coupon", result);
        if (result.success == true) {
          if (result.message == "No Gift Left for this Campaign") {
            Warningalert(result.message);
          } else {
            navigate("/GiftView", {
              state: result.response,
              replace: true,
            });
          }
        } else {
          Warningalert(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <div className="scanner-page">
      <div className="scanner-wrapper">
      <div className={`scanner-bg-img ${scanning ? 'scanning' : ''}`}>
      {!scanning && (
            <img
              src={require("../assetes/scan1.png")}
              style={{ borderRadius: 24 }}
              width={400}
              height={500}
            />
          )}
        </div>
        <div id="reader"></div>
      </div>
    </div>
  );
}
