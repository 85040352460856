import { BrowserRouter,Routes,Route,HashRouter } from "react-router-dom";
import Home from "./Components/HomeScreen/Home";
import Scanner from "./Components/ScannerScren/Scanner"
import GiftView from "./Components/ScannerScren/GiftView";

function App() {
  return (
    <div className="App">
     <HashRouter>
     <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/Scanner" element={<Scanner/>}/>
      <Route path="/GiftView" element={<GiftView/>}/>
     </Routes>
     </HashRouter>
    </div>
  );
  
}

export default App;
